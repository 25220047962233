// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  USER: '/user',
  PVD: '/provider',
  BOOKING: '/booking',
  BBS: '/bbs',
  GS: '/gs',
  CDC: '/cdc',
  AT: '/at',
  TS: '/ts',
  APP: '/app',
  CODE: '/code',
  SYSTEM: '/system',
  TAP: '/tap',
  ADMIN: '/admin',
  MSG: '/message',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    overview: `${ROOTS.DASHBOARD}/overview`,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },

  user: {
    root: `${ROOTS.USER}`,
    new: `${ROOTS.USER}/new`,
    list: `${ROOTS.USER}/list`,
    edit: (id) => `${ROOTS.USER}/${id}`,
  },

  provider: {
    root: `${ROOTS.PVD}`,
    new: `${ROOTS.PVD}/new`,
    list: `${ROOTS.PVD}/list`,
    edit: (id) => `${ROOTS.PVD}/${id}`,
  },

  admin: {
    root: `${ROOTS.ADMIN}`,
    new: `${ROOTS.ADMIN}/new`,
    list: `${ROOTS.ADMIN}/list`,
    edit: (id) => `${ROOTS.ADMIN}/${id}`,
  },

  booking: {
    root: `${ROOTS.BOOKING}`,
    all: `${ROOTS.BOOKING}`,
    app: `${ROOTS.BOOKING}/C15001`,
    web: `${ROOTS.BOOKING}/C15002`,
    hotel: `${ROOTS.BOOKING}/C15003`,
    online: `${ROOTS.BOOKING}/C15004`,
    etc: `${ROOTS.BOOKING}/C15005`,
    new: `${ROOTS.BOOKING}/edit/new`,
    list: `${ROOTS.BOOKING}/list`,
    edit: (code, id) => `${ROOTS.BOOKING}/${code}/edit/${id}`,
  },

  gs: {
    root: `${ROOTS.GS}`,
    new: `${ROOTS.GS}/new`,
    list: `${ROOTS.GS}/list`,
    edit: (id) => `${ROOTS.GS}/${id}/edit`,
  },
  cdc: {
    root: `${ROOTS.CDC}`,
    new: `${ROOTS.CDC}/new`,
    list: `${ROOTS.CDC}/list`,
    edit: (id) => `${ROOTS.CDC}/${id}/edit`,
  },
  at: {
    root: `${ROOTS.AT}`,
    new: `${ROOTS.AT}/new`,
    list: `${ROOTS.AT}/list`,
    edit: (id) => `${ROOTS.AT}/${id}/edit`,
  },
  ts: {
    root: `${ROOTS.TS}`,
    new: `${ROOTS.TS}/new`,
    list: `${ROOTS.TS}/list`,
    edit: (id) => `${ROOTS.TS}/${id}/edit`,
  },

  app: {
    main: `${ROOTS.APP}/main`,
    intro: `${ROOTS.APP}/intro`,
  },

  code: {
    root: `${ROOTS.CODE}`,
    new: `${ROOTS.CODE}/new`,
    list: `${ROOTS.CODE}/list`,
    edit: (id) => `${ROOTS.CODE}/${id}/edit`,
  },

  system: {
    root: `${ROOTS.SYSTEM}`,
    language: `${ROOTS.SYSTEM}/language`,
    serviceArea: `${ROOTS.SYSTEM}/serviceArea`,
    code: `${ROOTS.SYSTEM}/code`,
    admin: `${ROOTS.SYSTEM}/admin`,
  },

  tap: {
    root: `${ROOTS.TAP}`,
    edit: (id) => `${ROOTS.TAP}/${id}/edit`,
  },

  bbs: {
    qna: {
      new: `${ROOTS.BBS}/qna/new`,
      list: `${ROOTS.BBS}/qna/list`,
      edit: (id) => `${ROOTS.BBS}/qna/${id}/edit`,
    },
    faq: {
      new: `${ROOTS.BBS}/faq/new`,
      list: `${ROOTS.BBS}/faq/list`,
      edit: (id) => `${ROOTS.BBS}/faq/${id}/edit`,
    },
    main: {
      new: `${ROOTS.BBS}/main/new`,
      list: `${ROOTS.BBS}/main/list`,
      edit: (id) => `${ROOTS.BBS}/main/${id}/edit`,
    },
    intro: {
      new: `${ROOTS.BBS}/intro/new`,
      list: `${ROOTS.BBS}/intro/list`,
      edit: (id) => `${ROOTS.BBS}/intro/${id}/edit`,
    },
    promotion: {
      new: `${ROOTS.BBS}/promotion/new`,
      list: `${ROOTS.BBS}/promotion/list`,
      edit: (id) => `${ROOTS.BBS}/promotion/${id}/edit`,
    },
    notice: {
      new: `${ROOTS.BBS}/notice/new`,
      list: `${ROOTS.BBS}/notice/list`,
      edit: (id) => `${ROOTS.BBS}/notice/${id}/edit`,
    },
  },
  message: {
    push: {
      new: `${ROOTS.MSG}/push/new`,
      list: `${ROOTS.MSG}/push/list`,
      edit: (id) => `${ROOTS.MSG}/push/${id}/edit`,
    },
    sms: {
      new: `${ROOTS.MSG}/sms/new`,
      list: `${ROOTS.MSG}/sms/list`,
      edit: (id) => `${ROOTS.MSG}/sms/${id}/edit`,
    },
    email: {
      new: `${ROOTS.MSG}/email/new`,
      list: `${ROOTS.MSG}/email/list`,
      edit: (id) => `${ROOTS.MSG}/email/${id}/edit`,
    },
  },
};
